import Link from "next/link";
import { Button } from "../ui/button";
import React from "react";

export default function WhatsAppButton({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Button asChild>
      <Link
        aria-label="Send us a message on WhatsApp"
        target="_blank"
        href={"https://wa.me/+6587509882?text=" + "Hello Heavens Gate, I would like to enquire on your funeral services"}
        className="flex items-center gap-2"
      >
        {children}
      </Link>
    </Button>
  );
}
